<template>
    <st-page :title="titleComputed" :backLink="backLink" layout="tab" :subtitle="applicationDate" pageCustomClass="application-view">
        <loader v-if="showLoader" />
        <template #additional-title v-if="applicationStatus">
            <div v-can:read="'applicationsStaff'" :class="`label label-lg label-inline label-light-${applicationStatus.type} ml-6`">{{ applicationStatus.text }}</div>
            <div v-can:read="'applicationsStaff'" :class="`label label-lg label-inline label-light-${applicationStatus.type} ml-6`" v-if="hasRelatedApplications && waitForRelatedApplications">{{ $t("APPLICATION.FORM.PENDING_RELATED_APPLICATIONS") }}</div>
        </template>
        <template #toolbar>
            <application-view-toolbar v-if="!isLoading" v-can:read="'applicationsStaffOwn'"/>

            <div class="row" v-can:edit="'application'">
                <st-button
                    v-if="needsReview"
                    variant="light"
                    class="st-btn-text-warning"
                    :spinner="isLoadingSaveDraft"
                    :callback="sendClarification">
                    <i class="fa fa-question"></i>
                    {{ $t("APPLICATION.FORM.SEND_REVIEW") }}
                </st-button>
            </div>
        </template>

        <b-tabs class="st-tabs" justified lazy v-model="activeTab">
            <b-tab
                v-for="tab in tabs"
                :key="tab.index"
                :disabled="tab.disabled"
                :title-item-class="navItemClass(tab)"
                @click.stop="goToPage(tab)"
            >
                <template #title>
                    <div class="d-flex justify-content-center w-100">
                        <i :class="['fas', tab.icon]"></i>
                        <span class="text-uppercase ml-4">{{
                            $t(tab.title)
                        }}</span>
                    </div>
                </template>
                <component
                    :is="tab.componentType"
                    :applicationId="id"
                    :ref="tab.ref"
                    v-if="!isLoading"
                    @componentEvent="handleEvent"
                    @app-summary-mounted="handleAppSummaryMounted"
                    :noBack="tab.noBack"
                >
                </component>
            </b-tab>
        </b-tabs>
    </st-page>
</template>

<script>
import { ApplicationsPermissions } from "@/modules/applications/applications-permissions";
import { mapActions, mapGetters } from "vuex";
import Message from "@/shared/message/message";
import moment from 'moment';
import { ApplicationModel } from "@/modules/applications/models/application-model";

const { statuses } = ApplicationModel;

export default {
    name: "ApplicationsView",
    components: {
        ApplicationViewToolbar: () => import("@/modules/applications/components/view-application/ApplicationViewToolbar"),
        ApplicationDetailsTab: () => import("@/modules/applications/components/view-application/ApplicationDetailsTab"),
        ApplicationDocumentsTab: () => import("@/modules/applications/components/view-application/documents/ApplicationDocumentsTab"),
        ApplicationActivityTab: () => import("@/modules/applications/components/view-application/ApplicationActivityTab"),
        ApplicationDocumentsEdit: () => import('@/modules/applications/components/view-application/documents/ApplicationDocumentsEdit.vue'),
    },
    props: {
        id: String,
    },
    data() {
        return {
            activeTab: 0,
            waitForRelatedApplications: false,
            goToSectionId: this.$route.query?.goToSection ?? '',
        };
    },
    computed: {
        ...mapGetters({
            record: "applications/form/record",
            loading: "shared/loading",
            currentUser: "auth/currentUser",
            uploadAccessToken: "applications/form/uploadAccessToken",
            appConfig: "shared/appConfig",
        }),
        hasPermissionToOrganisationsApplication() {
            return new ApplicationsPermissions(this.currentUser).readStaff;
        },
        hasPermissionToStaffOwnApplications() {
            return new ApplicationsPermissions(this.currentUser).readOwnStaff;
        },
        hasPermissionToReadStaff() {
            return this.hasPermissionToOrganisationsApplication || this.hasPermissionToStaffOwnApplications;
        },
        applicationTypeName() {
            return this.record?.application_type_name;
        },
        titleComputed() {
            if (!this.id) return this.$t("APPLICATIONS.VIEW.TITLE");
            if (!this.record) return '';
            if (this.record?.status === 'draft') {
                return this.applicationTypeName;
            }

            const uid = this.record?.identification_number;

            return `${uid} - ${this.applicationTypeName}`;
        },
        backLink() {
            return this.id ? { name: "applications" } : "";
        },
        isLoading() {
            return this.loading["applications/find"];
        },
        isLoadingSaveDraft() {
            return this.loading["applications/update"];
        },
        tabs() {
            let tabs = [
                {
                    index: 0,
                    name: "application-detail",
                    title: this.$t("APPLICATION.TABS.APPLICATION"),
                    componentType: "ApplicationDetailsTab",
                    disabled: false,
                    ref: 'applicationDetails',
                },
            ];

            if (this.hasPermissionToReadStaff) {
                tabs.push(
                    {
                        index: 1,
                        name: "application-document-form",
                        title: this.applicationTypeName,
                        disabled: false,
                        componentType: "ApplicationDocumentsEdit",
                        noBack: true,
                    },
                    {
                        index: 2,
                        name: "application-documents",
                        title: this.$t("APPLICATION.TABS.DOCUMENTS"),
                        componentType: "ApplicationDocumentsTab",
                        disabled: false,
                        link: 'listDocuments'
                    },
                );
            }
            tabs.push(
                {
                    index: 3,
                    name: "application-activity",
                    title: this.$t("APPLICATION.TABS.ACTIVITY"),
                    componentType: "ApplicationActivityTab",
                    disabled: false,
                }
            )
            return tabs;
        },
        isLoadingSave() {
            return this.loading["applications/update"];
        },
        showLoader() {
            return (this.isLoading || this.loading["activities/get"]);
        },
        applicationDate() {
            if (!this.record?.application_date) return '';
            return moment(new Date(this.record.application_date)).format(this.appConfig.DATE_FORMAT);
        },
        applicationStatus() {
            if (!this.record) return null;
            return statuses.find((el) => el.value === this.record.internal_status);
        },
        needsReview() {
            return this.record?.status === 'review';
        },
        hasRelatedApplications() {
            if (!this.record) return false;

            return this.record?.related_application_ids?.length > 0;
        }
    },
    methods: {
        ...mapActions({
            doFind: "applications/form/find",
            doClear: "applications/form/clear",
            doUpdate: "applications/form/update",
            doUpload: "applications/form/upload",
            resetUserActions: "applications/form/doResetUserActions",
            sendComment: "applications/activity/sendComment",
            doFindUserActions: "applications/form/findUserActions",
        }),
        navItemClass(tab) {
            if (tab?.disabled) return "";
            if (this.id) return "nav-item--success";
            return "nav-item--warning";
        },
        async goToPage(tab) {
            if (tab?.link) {
                await this.$router.push({
                    name: tab.link
                });
            }
        },
        async generateData(asDraft) {
            const mainRef = this.$refs.applicationDetails[0].$refs;
            const genFormData = await mainRef.generateForm.getData(asDraft);

            const formData = {
                ...genFormData,
                applicant_user: {
                    first_name: this.currentUser.first_name,
                    last_name: this.currentUser.last_name,
                    email: this.currentUser.email,
                    phone_number: this.currentUser.phone_number,
                },
            };

            return formData;
        },
        handleEvent(callback) {
            if (this[callback]) {
                this[callback]();
            }
        },
        async sendClarification() {
            const data = await this.generateData(false);

            const identificationFiles = [];
            let attachedFiles = [];
            if (data.form?.attachments?.length) {
                const formData = new FormData();
                let needUpload = false;
                for( let i = 0; i < data.form?.attachments?.length; i++) {
                    const file = data.form.attachments[i];
                    if (file instanceof File) {
                        formData.append('files', file);
                        needUpload = true;
                    } else {
                        identificationFiles.push(file);
                    }
                }
                if (needUpload) {
                    const uploadedFiles = await this.doUpload({
                        files: formData,
                        uploadAccessToken: this.uploadAccessToken,
                    });
                    attachedFiles = uploadedFiles.map((el) => ({
                        file_id: el.id,
                        filename: el.initialFilename,
                        created_date: el.created_date,
                        file_type: 'input',
                        created_by: `${this.currentUser.first_name} ${this.currentUser.last_name}`
                    }));
                }

                const payload = {
                    id: this.record.id,
                    values: {
                        identification_files: [
                            ...identificationFiles,
                            ...attachedFiles,
                        ],
                    },
                };

                try {
                    await this.doUpdate(payload);
                    await this.sendMessage();

                    Message.success("APPLICATION.NOTIFIERS.REVIEW_SENT");
                } catch {
                    Message.error('APPLICATION.VIEW.REVIEW_NOT_SENT');
                }

            }

        },
        async sendMessage() {
            const params = {
                id: this.record.id,
                loadStaff: this.hasPermissionToReadStaff,
                payload: {
                    comment: this.$t('APPLICATION.NOTIFIERS.REVIEW_SENT'),
                    visibility: 'public'
                }
            };

            await this.sendComment(params);
        },
        async getRelatedApplicationsActions() {
            const paramsProcessing = {
                query: `application_id eq ${this.record.id} and status eq PROCESSING`,
                sort: '-created_date'
            };
            const processingActions = await this.doFindUserActions(paramsProcessing);
            if (processingActions.length) {
                let relatedApplicationsAction = processingActions.filter((el) => el.action_type === 'WAIT_FOR_RELATED_APPLICATIONS');
                this.waitForRelatedApplications = relatedApplicationsAction.length > 0;
            }
        },
        goToSection(sectionID) {
            const el = this.$refs.applicationDetails[0].$refs[sectionID].$el;
            if (el) {
                const elementPosition = el.offsetTop;
                window.scrollTo({
                    top: elementPosition,
                    behavior: 'smooth',
                });
            }
        },
        handleAppSummaryMounted() {
            this.$nextTick(() => {
                // scroll to section Id section after the summary section is mounted
                if (this.goToSectionId) {
                    setTimeout(() => {
                        this.goToSection(this.goToSectionId);
                        // clear query
                        this.$router.replace({
                            ...this.$router.currentRoute,
                            query: null
                         });
                    }, 1000);
                }
            });
        },
    },
    async created() {
        if (this.id && !this.record) {
            await this.doFind({
                id: this.id,
                loadStaff: this.hasPermissionToReadStaff,
            });
        }

        if (!this.id) {
            await this.doClear();
        }

        if (this.$route.params?.activeTab === 'listDocuments') {
            this.activeTab = 1;
            await this.goToPage({link: this.$route.params.activeTab})
            await this.doFind({
                id: this.$route.params.id,
                loadStaff: this.hasPermissionToReadStaff,
            });
            this.$router.push({
                name: 'editDocument'
            });
        }

        this.resetUserActions();

        if (this.hasPermissionToReadStaff) {
            await this.getRelatedApplicationsActions();
        }
    },
};
</script>
<style scoped>
    @import '~quill/dist/quill.snow.css';
</style>
